import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Hero, Button, IconBox, SectionHeader } from "../components/common";

import LoveTree from "../assets/images/vectors/love-tree.svg";
import { apiURL } from "../helpers";
import { navigate, graphql, StaticQuery } from "gatsby";

function ThanksForSubscribingPage({ location: propLocation }) {
  const [state, setState] = useState({ loading: false, user: {}, show: false });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const search = location.search;
      const query = new URLSearchParams(search);
      const [email] = [query.get("email")];
      if (email) {
        verifyToken(email);
      } else {
        navigate("/");
      }
    }
  }, []);
  const verifyToken = async (email) => {
    setState({ ...state, loading: true, user: { email }, show: false });

    try {
      const { data } = await axios.post(apiURL + `/email-subscribers`, {
        email,
        verify: true,
      });

      switch (data.code) {
        case "subscriber_exist":
          return setState({ ...state, show: true });
        case "subscriber_not_exist":
          return navigate("/");
        default:
          return navigate("/");
      }
    } catch {
      return navigate("/");
    }
  };

  const handleFreeDownload = async () => {
    if (typeof window !== "undefined") {
      window.open(
        "https://shahadat.notion.site/57a6f9740e814139a5528c421bf66c1d?v=952db12bc64d41ee946d958e3bea6432",
        "__blank"
      );
    }
  };

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Thanks for subscribing"
        path={propLocation?.pathname}
      />
      {state.show && (
        <>
          <section className="mt-20 mb-16">
            <Hero
              subTitle="Email Subscription Confirmed"
              title={
                <>
                  Here’s Your Download
                  <br /> Get The List of 50+ Finest Fonts
                </>
              }
              description="Thanks for confirming your email. You can download your desired fonts list here. We also sent you another email with a download link to access this later."
            />
          </section>
          <section className="mb-40">
            <div className="flex items-center justify-center">
              <StaticQuery
                query={graphql`
                  query SubscribersGiveaway {
                    strapiSubscribersGiveaway {
                      data {
                        attributes {
                          FileDownloadURL
                        }
                      }
                    }
                  }
                `}
                render={({
                  strapiSubscribersGiveaway: {
                    data: {
                      attributes: { FileDownloadURL },
                    },
                  },
                }) => (
                  <Button
                    icon="vg-download"
                    text="Download Now"
                    onClick={() => handleFreeDownload(FileDownloadURL)}
                  />
                )}
              />
            </div>
          </section>
          <section className="mb-24">
            <div className="text-center mb-12">
              <SectionHeader
                art
                artVector={LoveTree}
                title="More From The Next Week"
                description="As a sweet subscriber of our Vackground family, you will get more sweet snacks every week. Make sure to whitelist us in your mailbox to receive these effortlessly."
              />
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
              <IconBox icon="vg-gift" title="Cool Freebies">
                Get curated freebies from the internet and move faster!
              </IconBox>
              <IconBox icon="vg-bell" title="Latest Products">
                Create visually appealing content and steal the spotlight!
              </IconBox>
              <IconBox icon="vg-dollar-sign" title="Deals & Discounts">
                Save money with our products and exclusive partner deals!
              </IconBox>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
}

ThanksForSubscribingPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ThanksForSubscribingPage;
